body {
  margin: 0;
  font-family: mr-eaves-modern, sans-serif;
  font-weight: 300;

  font-style: normal;
  font-size: 16pt;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #ffeee6;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: "Mellisa";
  src: local("Mellisa"),
   url("./assets/Mellisa.woff") format("truetype");
  font-weight: normal;
  font-display: block;
}
